import { useMemo } from "react";
import { useParams } from "react-router-dom";

import getUserById from "../../api/getUser";
import getUserByUsername from "../../api/getUserByUsername";
import getUserByEmail from "../../api/getUserByEmail";
import mutateUser from "../../api/mutateUser";
import useEditing from "../../hooks/useEditing";
import SearchFields from "../SearchFields";
import ActionButtons from "../ActionButtons";
import UserFields from "../UserFields";
import Loader from "../Loader";

const UserEdit = () => {
  const { query } = useParams();

  const getUser = useMemo(() => {
    if (query.includes("@")) {
      return getUserByEmail;
    }

    if (query.length === 36) {
      return getUserById;
    }

    return getUserByUsername;
  }, [query]);

  const {
    isLoading,
    isNotFound,
    data: user,
    updateData: updateUser,
    handlePairedFieldsChange,
    handleReset,
    handleSubmit,
    mutate,
  } = useEditing(query, getUser, mutateUser, "user");

  const isLoaded = !!Object.keys(user).length;

  return (
    <main className="main">
      {isLoading && <Loader />}
      <div className="flex justify-between items-center mb-4">
        <SearchFields
          pathParam={query}
          placeholder="Search by username, email or ID"
          entity="users"
        />
        {isLoaded && (
          <ActionButtons handleReset={handleReset} handleSave={handleSubmit} />
        )}
      </div>
      {isNotFound && (
        <p className="mt-10 font-semibold highlighted-error">
          No User is found
        </p>
      )}
      {isLoaded && (
        <UserFields
          isLoading={isLoading}
          user={user}
          updateUser={updateUser}
          handlePairedFieldsChange={handlePairedFieldsChange}
          saveUser={mutate}
        />
      )}
    </main>
  );
};

export default UserEdit;
